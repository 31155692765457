import { useQuery } from 'react-query';

async function fetchAuthedUser() {
  const response = await fetch('/api/users/me');
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useAuthedUser() {
  return useQuery('me', fetchAuthedUser, {
    refetchOnWindowFocus: false,
  });
}
