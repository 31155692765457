import Router from 'next/router';
import { Amplify, Hub } from 'aws-amplify';
import { region, userPoolId, userPoolWebClientId } from '../constants';

const setLoginError = (error) => {
  globalThis.setLoginError(error);
  Router.events.off('routeChangeComplete', setLoginError);
};

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    let error = data.payload.data.message;
    if (data.payload.data.message.includes('USER_NOT_FOUND')) {
      error =
        'You do not have an active account with us, please contact support for more information.';
    }
    if (data.payload.data.message.includes('USER_NOT_ACTIVATED')) {
      error =
        'Your account must be activated before you can access Edcover. Contact your school administrator.';
    }
    Router.events.on('routeChangeComplete', () => setLoginError(error));
  }
});

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://dashboard.edcover.co.uk';

Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: 'edcover.auth.eu-west-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: baseUrl,
      redirectSignOut: `${baseUrl}/login`,
      responseType: 'code',
    },
  },
  ssr: true,
});
